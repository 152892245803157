import { styled } from "@mui/material/styles";
import profile from "../assets/Profile.jpeg";
import Title from "./Title";

const Wrapper = styled("div")`
  width: 100%
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
`;

const NameCard = styled("div")`
  height: auto;
  width: 100%;
  background: rgb(220, 220, 220);
  border-radius: 20px;
  margin-bottom: 30px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ProfileWrapper = styled("div")`
  margin: 15px;
  border-radius: 5px;
  height: 150px;
  width: 150px;
  overflow: hide;
`;

const ProfileImg = styled("img")`
  height: 150px;
  width: 150px;
`;

const TextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Name = styled("div")`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  margin-top: 20px;
`;

const JobTitle = styled("div")`
  font-family: Roboto, sans-serif;
  font-weight: lighter;
`;

const List = styled("ul")`
  margin: 10px 0 15px 0;
  padding: 0 15px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;

const ListItem = styled("li")`
  margin: 5px 0;
`;

const Message = styled("h2")`
  font-family: Lobster, cursive;
`;

const TeamInfo = () => {
  return (
    <Wrapper>
      <Title>The Team</Title>
      <NameCard>
        <ProfileWrapper>
          <ProfileImg src={profile} alt="profile" />
        </ProfileWrapper>
        <TextWrapper>
          <Name>Paul Edwards</Name>
          <JobTitle>
            President – Resides in Metro Atlanta- covers Georgia and E.
            Tennessee
          </JobTitle>
          <List>
            <ListItem>17+ years as a distributor sales representative</ListItem>
            <ListItem>20+ years Representing Manufacturers</ListItem>
          </List>
        </TextWrapper>
      </NameCard>
      <NameCard>
        <ProfileWrapper>
          <ProfileImg src={profile} alt="profile" />
        </ProfileWrapper>
        <TextWrapper>
          <Name>Jack Bates</Name>
          <JobTitle>
            Associate – Resides in Huntsville, AL- covers Alabama, NW Florida,
            W. Tennessee and Mississippi.
          </JobTitle>
          <List>
            <ListItem>18+ years as a Manufacturer representative</ListItem>
            <ListItem>4+ years as a distributor sales representative</ListItem>
          </List>
        </TextWrapper>
      </NameCard>
      <Message>We will be glad to provide references upon request.</Message>
    </Wrapper>
  );
};

export default TeamInfo;
