import { styled } from "@mui/material/styles";
import Title from "./Title";

const Wrapper = styled("div")`
  width: 100%
  height: auto;
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
`;

const InfoContainer = styled("div")`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContactCard = styled("div")`
  margin-right: 15px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

const Name = styled("h3")`
  font-family: Roboto, sans-serif;
  margin-bottom: 10px;
`;

const Contact = () => {
  return (
    <Wrapper>
      <Title>Contact Info.</Title>
      <InfoContainer>
        <ContactCard>
          <Name>Paul Edwards</Name>
          <p>Phone: 404-597-3947</p>
          <p>Email: edwards@premiersesales.com</p>
          <p>States: Georgia, E. Tennessee</p>
        </ContactCard>
        <ContactCard>
          <Name>Jack Bates</Name>
          <p>Phone: 205-533-1622</p>
          <p>Email: bates@premiersesales.com</p>
          <p>States: Alabama, NW Florida, W. Tennessee</p>
        </ContactCard>
      </InfoContainer>
    </Wrapper>
  );
};

export default Contact;
