import { styled } from "@mui/material/styles";
import headerPhoto from "../assets/construction2.jpeg";
import logo from "../assets/saw-blade.png";

const Container = styled("div")`
  width: 100%;
  height: 400px;
  background-image: url(${headerPhoto});
  background-color: rgba(0, 0, 255, 0);
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const ContentContainer = styled("div")`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledImg = styled("img")`
  width: 100px;
  height: 100px;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledHeading = styled("h1")`
  color: white;
  font-family: Lobster, cursive;
  font-weight: 300;
  text-shadow: 2px 2px 5px black;
  font-size: 60px;

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const Header = () => {
  return (
    <Container>
      <ContentContainer>
        <StyledImg src={logo} alt="logo" />
        <StyledHeading>Premier Southeast Sales</StyledHeading>
      </ContentContainer>
    </Container>
  );
};

export default Header;
