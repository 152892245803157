import { styled } from "@mui/material/styles";
import Title from "./Title";

const Wrapper = styled("div")`
  width: 100%
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
`;

const Paragraph = styled("div")`
  text-indent: 50px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 25px;
`;

const Divider = styled("div")`
  height: 30px;
  width: 100%;
`;

const About = () => {
  return (
    <Wrapper>
      <Title>Who We Are</Title>
      <Paragraph>
        Premier Southeast Sales is an independent representative agency serving
        distributors in Georgia, Alabama, Northwest Florida, Mississippi and
        Tennessee since 2005.
      </Paragraph>
      <Divider />
      <Paragraph>
        Our combined 59+ years of experience in construction and industrial
        distribution is unmatched in our area…. on the jobsite, commercial,
        industrial, OEM and utilities. This is a people business and we
        understand what it takes to help our distributor partners be successful.
        Our goal is to represent quality manufacturers to quality distributors
        with exceptional knowledge and professionalism. 2023 has been another
        banner year for our agency and we expect to grow our business again in
        2024. Our growth will be with the manufacturers that we currently
        represent with an eye on opportunities to expand our product offering
        where it makes sense for us and our business partners. I have listed
        some personal information about us as well as the states we cover. If
        there is an opportunity to discuss representation for your company, we
        look forward to discussing a plan to make it work.
      </Paragraph>
    </Wrapper>
  );
};

export default About;
