import { styled } from "@mui/material/styles";

const Wrapper = styled("div")`
  height: auto;
  width: 100%;
  background: rgb(220, 220, 220);
  display: flex;
  justify-content: flex-start;
  font-family: Roboto, sans-serif;
  font-weight: 300;
`;

const TextWrapper = styled("div")`
  margin: 20px 30px;
  display: flex:
  flex-direction: column;
`;

const Text = styled("p")`
  margin: 0 0 10px;
`;

const Footer = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Text>Premier Southeast Sales. TM 2021</Text>
        <div>
          Icons made by{" "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </TextWrapper>
    </Wrapper>
  );
};

export default Footer;
